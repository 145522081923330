$grid-head-bg-color: #eff1f4;

@mixin error {
    border: 2px solid #ff7675 !important;
    box-shadow: 0 0 10px #fab1a0 !important;
}

.ag-grid-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	.grid-actions-top {
		display: flex;
		flex-direction: row-reverse;
		margin: 5px;
		right: 10px;
		top: 27px;
		z-index: 1000;
		.anticon {
			font-size: 18px;
			margin-left: 10px;
			cursor: pointer;
		}
	}
	.ag-header {
		background: linear-gradient(90deg, rgba(255,77,77,1) 0%, rgba(255,121,121,1) 35%);
	}
	.ag-root-wrapper {
		border-color: $grid-head-bg-color;
		border-color: var(--ag-border-color, $grid-head-bg-color);
		border-radius: 2px;
	}
	input {
		&:focus {
			outline: none !important;
			border:1px solid #636e72 !important;
			box-shadow: 0 0 10px #b2bec3 !important;
		}
	}

	.ant-checkbox-checked {
		.ant-checkbox-inner {
			background-color: #1890ff !important;
			border-color: #1890ff !important;
		}
	}

	.ant-select {
		&:not(.ant-select-disabled) {
			&.error {
				:hover {
					&.ant-select-selector {
						border: none !important;
					}
				}
			}
		}
	}
}

.ag-dnd-ghost {
	width: 200px !important;
}

.ag-theme-balham {
	.ag-ltr {
		.ag-row-group-leaf-indent {
			margin-left: 24px !important;
            padding: 0px !important;
		}
	}
}

.ag-row-odd {
	background-color: #f5f6fa !important;
}

.header-detail-popup {
	font-size: 12px !important;
}

.om-row-has-error {
    .ant-checkbox-checked {
        .ant-checkbox-inner {
            background-color: #ff6b6b !important;
            border-color: #ff6b6b !important;
        }
      }
}

.ag-has-focus {
	.ag-cell-focus {
		&:not(.ag-cell-range-selected) {
			background-color: #dfe6e9;
			border: 1px solid #dfe6e9 !important;
		}
	}
}

.ant-input {
    &.error {
        @include error;
    }
}

.ant-picker {
    &.error {
        @include error;
    }
}

.ant-select{
    &.error {
        .ant-select-selector {
            @include error;
        }
    }
}

.ag-status-bar {
	padding: 0px !important;
}

.pc {
	.ag-root-wrapper {
		border-bottom: none;
		border-left: none;
		border-right: none;
	}
}