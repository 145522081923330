@mixin placeholder-style {
    font-size: 10px;
    font-weight: 500;
}

.reschedules {
    .ant-radio-group:not(.filter-radio-group){
        position: absolute;
        padding: 5px 0 0 0;
        .ant-radio-button-wrapper{
            background-color: #e0e0e0;
            border: 1px solid #c2c2c2;
            border-color: #c2c2c2;
            height: 26px;
            line-height: 24px;
            padding: 0px 30px;
            &.ant-radio-button-wrapper-checked{
                border-color: #EF373E !important;
            }
            &:first-child{
                border-radius: 4px 0 0 4px;
            }
            &:last-child{
                border-radius: 0 4px 4px 0;
            }
        }
    }

    .reschedules-grid-container {
        .status-error {
            border: 1px solid #d63031;
            line-height: 24px;
            height: 25px;
            .error-icon {
                font-size: 14px;
                float: right;
                padding: 5px;
                color: #d63031; 
            }
        }
        .ag-row-selected {
            background-color: #dff9fb !important;
        }

        .ant-select{
            width: 100% !important;
            padding: 0 !important;
            height: 26px !important;
            border-radius: 8px !important;
        }
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
            height: 24px !important;
            padding: 0 11px !important;
        }

        .ag-cell {
            border-right: 1px solid #d9dcde !important;
        }
    }

    .grid-actions-top {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        margin: 5px;
        &> div {
            margin-left: 10px;
            .ant-icon {
                font-size: 16px;
            }
        }
    }

    .ag-cell-value {
        padding: 2px 5px !important;
    }

    .ant-input {
        border-radius: 8px;
        font-size: 12px !important;
    }

    .ant-select-selector {
        border-radius: 8px !important;
        font-size: 12px !important;
    }

    .ant-picker {
        border-radius: 8px !important;
        font-size: 12px !important;
    }
}

.custom-dpickr-switch {
    .ant-switch-inner {
        font-size: 10px !important;
    }
}

.ag-custom-component-popup {
    .ant-select-item-option-content {
        font-size: 12px;
    }
}